import { Button, Container } from "react-bootstrap";
import { NEWS, NEWS_TITLE } from "../../components/core/nav/NavigationItems";
import NewsTile, {
  NEWS_PER_PAGE,
} from "../../components/core/ux/tiles/NewsTile";

import HomeShape from "../../components/core/ux/HomeShape";
import Layout from "../../components/core/Layout";
import NewsQuery from "../../components/queries/NewsQuery";
import React from "react";

const NewsIndexPage = () => {
  const newsFiles = NewsQuery();
  const [currentPage, setCurrentPage] = React.useState(1);
  const maxPage = Math.ceil(newsFiles.length / NEWS_PER_PAGE);

  return (
    <Layout page={NEWS}>
      <HomeShape />
      <Container>
        <h2 className="text-uppercase h-muted">{NEWS}</h2>
        <h1 className="text-uppercase h bolder">{NEWS_TITLE}</h1>
        <div data-sal="slide-up" data-sal-duration="900">
          <h3 className="headline">
            Neues von und über <strong>new.space</strong>
          </h3>
        </div>
        <p>
          Wie entwickelt sich der Markt der Pflege-Immobilien? <br />
          Welche Chancen entstehen für Investoren und Betreiber? <br />
          Welche Ideen und Projekte von <strong>new.space</strong> sind künftig
          besonders interessant? <br /> Finden Sie hier regelmäßig
          Branchen-Insight, Trends und Tendenzen sowie Neues von{" "}
          <strong>new.space</strong>.
        </p>
        <div style={{ display: "flex", flexWrap: "wrap" }} className="my-5">
          {newsFiles.length > 0 &&
            Array.from({ length: currentPage }, (_, i) => i + 1).map((p) => {
              const start = (p - 1) * NEWS_PER_PAGE;
              const end = start + NEWS_PER_PAGE;
              return newsFiles
                .slice(start, end)
                .map((news, index) => <NewsTile key={index} file={news} />);
            })}
        </div>
        {currentPage < maxPage && (
          <Container className="text-center my-5">
            <Button
              onClick={() => setCurrentPage((prev) => ++prev)}
              title="Mehr News zeigen"
            >
              more
            </Button>
          </Container>
        )}
      </Container>
    </Layout>
  );
};

export default NewsIndexPage;
