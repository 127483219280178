import { graphql, useStaticQuery } from "gatsby";

export default function NewsQuery() {
  const mdPost = useStaticQuery(graphql`
    query NQuery {
      allMarkdownRemark {
        totalCount
        edges {
          node {
            frontmatter {
              slug
              title
              category
              date
              image
            }
            html
          }
        }
      }
    }
  `);
  return mdPost.allMarkdownRemark.edges
    .map((f) => f.node)
    .filter(
      (p) =>
        typeof p.frontmatter.slug === "string" &&
        p.frontmatter.slug.startsWith("n")
    )
    .sort((a, b) => {
      if (a.frontmatter.date !== null) {
        if (a.frontmatter.date > b.frontmatter.date) {
          return -1;
        }
        if (a.frontmatter.date < b.frontmatter.date) {
          return 1;
        }
      }
      return 0;
    });
}
