import { Card } from "react-bootstrap";
import { Link } from "gatsby";
import React from "react";

export const IMG_BASE_HEIGHT = 310;

const ProjectCard = ({ isPortrait, url, image, hover, shortname }) => {
  return (
    <Card style={{ marginBottom: "-2.4em" }}>
      <Link to={url} className="p-link" title={shortname}>
        <div
          style={{
            maxWidth: "100%",
            minHeight: `${IMG_BASE_HEIGHT}px`,
            height: isPortrait
              ? `${IMG_BASE_HEIGHT * 2.21}px`
              : `${IMG_BASE_HEIGHT}px`,
            //borderTopLeftRadius: "9px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundImage:
              image === ""
                ? hover
                  ? "linear-gradient(rgb(248, 227, 178, 0.7), rgb(248, 227, 178, 0.3))"
                  : "linear-gradient(rgb(248, 227, 178, 0.3), rgb(248, 227, 178, 0.7))"
                : hover
                ? `linear-gradient(rgb(248, 227, 178, 0.7), rgb(248, 227, 178, 0.3)), url(${image})`
                : `url(${image})`,
          }}
        />
      </Link>
      <small className="pt-1 text-uppercase h-muted">{shortname}</small>
    </Card>
  );
};

export default ProjectCard;
