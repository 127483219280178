import { Container } from "react-bootstrap";
import React from "react";

const HomeShape = (props) => {
  let projectName = [null, undefined].includes(props.shortname)
    ? ""
    : props.shortname;
  if (projectName.includes(",")) {
    const splittedName = projectName.split(",");
    projectName = (
      <>
        {splittedName.shift()},
        <br />
        {splittedName.join(",")}
      </>
    );
  }

  return (
    <Container className="d-flex justify-content-end">
      <object>
        <small className="text-uppercase h-muted">{props.location}</small>
        {projectName !== "" && (
          <strong
            className="text-uppercase bolder"
            lang="de"
            style={{ hyphens: "auto" }}
          >
            {projectName}
          </strong>
        )}
      </object>
    </Container>
  );
};
export default HomeShape;
