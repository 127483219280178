import { graphql, useStaticQuery } from "gatsby";

export const NULL_STRING = "None";

export default function JobsQuery() {
  const mdJob = useStaticQuery(graphql`
    query JQuery {
      allMarkdownRemark {
        totalCount
        edges {
          node {
            frontmatter {
              slug
              position
              date
            }
            html
          }
        }
      }
    }
  `);
  return mdJob.allMarkdownRemark.edges
    .map((f) => f.node)
    .filter(
      (p) =>
        typeof p.frontmatter.slug === "string" &&
        p.frontmatter.slug.startsWith("j")
    );
}
