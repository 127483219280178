import { Card } from "react-bootstrap";
import { IMG_BASE_HEIGHT } from "./ProjectCard";
import { Link } from "gatsby";
import { NEWS_PATH } from "../../nav/NavigationItems";
import { NULL_STRING } from "../../../queries/JobsQuery";
import React from "react";

export const NEWS_PER_PAGE = 6,
  NEWS_PER_ROW = 3;

const NewsTile = ({ file, isHome }) => {
  const [hover, setHover] = React.useState(false);
  const meta = file.frontmatter,
    image = [NULL_STRING, "/media/"].includes(meta.image) ? "" : meta.image,
    url = isHome ? NEWS_PATH.slice(1) + meta.slug : meta.slug;

  return (
    <span
      className="tile"
      key={meta.slug}
      style={{
        padding: "1em 1em 1em 0",
        flexGrow: 1,
        minWidth: `${100 / NEWS_PER_ROW}%`,
        maxWidth: `${IMG_BASE_HEIGHT}px`,
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onFocus={() => setHover(true)}
      onBlur={() => setHover(false)}
      role="button"
      tabIndex="-1"
    >
      <Card>
        <Link to={url} className="p-link" title={meta.title}>
          <div
            style={{
              maxWidth: "100%",
              minHeight: `${IMG_BASE_HEIGHT}px`,
              //borderTopLeftRadius: "9px",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundImage:
                image === ""
                  ? hover
                    ? "linear-gradient(rgb(248, 227, 178, 0.7), rgb(248, 227, 178, 0.3))"
                    : "linear-gradient(rgb(248, 227, 178, 0.3), rgb(248, 227, 178, 0.7))"
                  : hover
                  ? `linear-gradient(rgb(248, 227, 178, 0.7), rgb(248, 227, 178, 0.3)), url(${image})`
                  : `url(${image})`,
            }}
          />
        </Link>
        <small className="pt-1 text-uppercase h-muted">{meta.title}</small>
      </Card>
    </span>
  );
};

export default NewsTile;
